import { AppContainer } from '@components/AppContainer'
import { Footer } from '@components/Footer'
import { Menu } from '@components/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, ClickAwayListener, Drawer, useTheme } from '@mui/material'
import { ConditionalRender } from 'core/src/components'
import { LogoIcon } from 'core/src/components/Icons/Logo'
import { useNavigationContext } from 'core/src/providers/hooks'
import { pxToRem } from 'core/src/utils/styling'
import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { HeaderBarWrapper, MenuPill, NavLinkLogo } from 'src/style'

const HomePage = React.lazy(() => import('./pages/home/index'))
const B2CPage = React.lazy(() => import('./pages/b2c/index'))
const B2BPage = React.lazy(() => import('./pages/b2b/index'))
const WhoWeArePage = React.lazy(() => import('./pages/whoWeAre/index'))
const MissionPage = React.lazy(() => import('./pages/mission/index'))
const ContactsPage = React.lazy(() => import('./pages/contacts/index'))

const App = () => {
  const { isMobile } = useNavigationContext()
  const theme = useTheme()
  const [menuState, setMenuState] = useState<boolean>(false)

  return (
    <BrowserRouter>
      <AnimatePresence>
        <AppContainer closeMenu={() => setMenuState(false)} menuState={menuState}>
          <AppBar
            sx={{
              paddingInline: 10,
              paddingBlock: 3,
              background: isMobile ? theme.palette.background.paper : 'rgba(0,0,0,0.2)',
              borderRadius: isMobile ? `0 0 ${pxToRem(16)} ${pxToRem(16)}` : '0',
            }}
            elevation={0}
            position="fixed"
            color="transparent"
          >
            <HeaderBarWrapper>
              <MenuPill isMobile={isMobile}>
                <ConditionalRender
                  condition={isMobile}
                  render={() => (
                    <>
                      <NavLinkLogo to="/home">
                        <LogoIcon
                          fill={theme.palette.primary.main}
                          height={pxToRem(20)}
                          width={pxToRem(100)}
                        />
                      </NavLinkLogo>

                      <div onClick={() => setMenuState(true)}>
                        <MenuIcon fontSize="large" />
                      </div>
                    </>
                  )}
                />
                <ConditionalRender
                  condition={!isMobile}
                  render={() => (
                    <>
                      <NavLinkLogo to="/home">
                        <LogoIcon fill="white" height={pxToRem(40)} width={'40%'} />
                      </NavLinkLogo>
                      <Menu />
                    </>
                  )}
                />
              </MenuPill>
            </HeaderBarWrapper>
          </AppBar>
          <Routes>
            <Route path="" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/b2c" element={<B2CPage />} />
            <Route path="/b2b" element={<B2BPage />} />
            <Route path="/who-we-are" element={<WhoWeArePage />} />
            <Route path="/mission" element={<MissionPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            {/* // TODO add 404 page */}
          </Routes>
          <Footer />
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            open={menuState}
            onClickAway={() => menuState && setMenuState(false)}
          >
            <Drawer
              PaperProps={{
                sx: {
                  borderRadius: `0 0 ${pxToRem(24)} ${pxToRem(24)}`,
                },
              }}
              anchor={'top'}
              open={menuState}
              onClose={() => setMenuState(false)}
            >
              <Menu />
            </Drawer>
          </ClickAwayListener>
        </AppContainer>
      </AnimatePresence>
    </BrowserRouter>
  )
}

export default App
