import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import ApolloLinkTimeout from './apolloClientTimeout'

// https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids

const CACHING_POLICIES = {
  Car: {
    keyFields: ['uuid', 'updatedAt'],
  },
}

const cache = new InMemoryCache({
  typePolicies: {
    ...CACHING_POLICIES,
  },
})

export const getClient = (bffUrl: string) => {
  /**
   * The abort controller is passed on the fetch options that are provided to the fetch API when it is executed.
   * https://www.apollographql.com/docs/react/networking/advanced-http-networking/#constructor-options
   *
   * The fetch can receive an AbortSignal so that it can cancel the requests when the request is no longer
   * needed - https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#aborting_a_fetch
   *
   * The GraphQL client would throw errors when the fetch needs to be cancelled for any reason, like
   * unmounting/mounting component, re-running queries (useEffect), etc...
   */

  const abortController = new AbortController()
  const timeoutLink = new ApolloLinkTimeout()

  const httpLink = createHttpLink({
    // TODO: create env variable
    uri: operation => `${bffUrl}?name=${operation.operationName}`,
    fetchOptions: { signal: abortController.signal },
  })

  const timeoutHttpLink = timeoutLink.concat(httpLink)

  const activityMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
      },
    }))

    return forward(operation)
  })

  const removeTypenameLink = removeTypenameFromVariables()

  const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: from([removeTypenameLink, activityMiddleware, timeoutHttpLink]),
    connectToDevTools: true,
    cache,
  })
  return client
}
