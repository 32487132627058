import { ListItemStyled } from '@components/Footer/style'
import { Box, List, useTheme } from '@mui/material'
import { GridContainer } from '@pages/home/style'
import { LogoIcon } from 'core/src/components/Icons/Logo'
import { Body, Title } from 'core/src/components/Typography'
import { useNavigationContext } from 'core/src/providers/hooks'
import { pxToRem } from 'core/src/utils/styling'
import { NavLink } from 'react-router-dom'

export const Footer = () => {
  const theme = useTheme()
  const { isMobile } = useNavigationContext()
  return (
    <Box
      bgcolor={theme.palette.background.default}
      display={isMobile ? 'flex' : 'grid'}
      width={'100%'}
      paddingInline={isMobile ? pxToRem(30) : pxToRem(100)}
      paddingTop={isMobile ? pxToRem(30) : pxToRem(80)}
      paddingBottom={pxToRem(100)}
    >
      <Box
        gridTemplateColumns={isMobile ? '1fr' : '300px 1fr)'}
        display={'grid'}
        width={isMobile ? '100%' : 'auto'}
        gap={'20%'}
        alignItems={isMobile ? 'center' : 'left'}
        justifyContent={isMobile ? 'center' : 'left'}
      >
        <Box
          alignItems={isMobile ? 'center' : 'flex-start'}
          sx={{
            '> svg': {
              margin: isMobile ? '0 auto' : 'unset',
              display: 'block',
            },
          }}
        >
          <LogoIcon
            width={isMobile ? pxToRem(200) : pxToRem(300)}
            height={pxToRem(100)}
            fill={theme.palette.primary.main}
          />
        </Box>
        <Box>
          <GridContainer
            cols="1fr 1fr 1fr"
            align={isMobile ? 'center' : 'left'}
            justify={isMobile ? 'center' : 'left'}
            gap={10}
          >
            <div>
              <Title align={isMobile ? 'center' : 'left'}>About</Title>
              <List>
                <ListItemStyled>
                  <NavLink to={'/who-we-are'}>Chi siamo</NavLink>
                </ListItemStyled>
                <ListItemStyled>
                  <NavLink to={'/mission'}>Mission</NavLink>
                </ListItemStyled>
                <ListItemStyled>
                  <NavLink to={'/contacts'}>Contatti</NavLink>
                </ListItemStyled>
              </List>
            </div>
            <div>
              <Title align={isMobile ? 'center' : 'left'}>Astauto</Title>
              <List>
                <ListItemStyled>
                  <NavLink to={'/b2c'}>Privati</NavLink>
                </ListItemStyled>
                <ListItemStyled>
                  <NavLink to={'/b2b'}>Professionisti</NavLink>
                </ListItemStyled>
              </List>
            </div>
            <Body align="center">
              Astauto s.r.l
              <br />
              Via Fratelli Ugoni 36, Brescia
              <br /> P.IVA: 04120860988
            </Body>
          </GridContainer>
        </Box>
      </Box>
    </Box>
  )
}
