import { ListItem } from '@mui/material'
import { pxToRem } from 'core/src/utils/styling'
import styled from 'styled-components'

export const ListItemStyled = styled(ListItem)`
  margin-left: -16px;
  &:first-of-type {
    margin-top: ${pxToRem(16)};
  }
  body.isMobile & {
    justify-content: center;
    width: 100%;
    margin-left: unset;
    &:first-of-type {
      margin-top: unset;
    }
  }
`
