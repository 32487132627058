import { Card } from '@mui/material'
import { pxToRem } from 'core/src/utils/styling'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

const menuMobileCss = css`
  position: absolute;
  right: ${pxToRem(16)};
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MenuPill = styled(Card)<{ isMobile: boolean }>`
  background: ${({ theme }) => theme.palette.common.white};
  width: auto;
  border-radius: ${pxToRem(60)};
  margin: 0 auto;
  z-index: 1;
  body.isMobile & {
    ${menuMobileCss}
  }
`

export const HeaderBarWrapper = styled.div`
  display: grid;
  align-items: center;
  min-height: ${pxToRem(40)};
  grid-template-columns: auto;
  justify-content: center;
  width: 100%;
  gap: ${pxToRem(108)};
`

export const NavLinkLogo = styled(NavLink)`
  position: fixed;
  cursor: pointer;
  top: 4%;
  left: 4%;
  z-index: 0;
  width: auto;

  body.isMobile & {
    left: ${pxToRem(16)};
  }
`
